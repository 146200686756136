import React from 'react';
import PropTypes from 'prop-types';
import {Link as GatsbyLink} from 'gatsby-plugin-react-i18next';
import {OutboundLink} from 'gatsby-plugin-google-gtag';

import styled from '@emotion/styled';
import {css} from '@emotion/react';

import {breakpoints, colors, fonts, fontWeights} from '../../styles/theme';
import sendGoogleAnalyticsEvent from '../../helpers/utils/google-analytics';


/*
 * Common
 */
const commonTextStyles = css`
    font-family: ${fonts.sansSerif};
`;


/*
 * Private Elements
 */
/**
 * Generic Link component for both internal and external links
 *
 * @param {string} activeClassName - the class to use on active links
 * @param {boolean} partiallyActive - whether to set partial matches as active links
 * @param {string} to - the link to redirect to
 * @param {boolean} forceActive - whether to force the link to be displayed as active
 * @param {string} gaLabel - download link label to send to Google Analytics
 * @param {any} other - other props to apply to the link element
 * @param {node} children - content of the link
 */
const GeneralLink = ({activeClassName, children, partiallyActive, to, forceActive, gaLabel, ...other}) => {
    const otherProps = other || {};

    // Any internal link (intended for Gatsby) will start with exactly one slash, anything else is external
    const internal = /^\/(?!\/)/.test(to);

    // Download links should render using <a> element
    let download = false;
    if (otherProps.download) {
        download = true;
    }

    const classNames = [];
    if (forceActive && activeClassName) {
        classNames.push(activeClassName);
    }

    // Use Gatsby Link for internal links, and <a> for others
    if (internal && !download) {
        return (
            <GatsbyLink
                to={to}
                activeClassName={activeClassName}
                partiallyActive={partiallyActive}
                className={classNames.join(' ')}
                {...otherProps}
            >
                {children}
            </GatsbyLink>
        );
    }

    // If there are custom class names to use, add them to the existing ones
    if (classNames.length) {
        if (!otherProps.className) {
            otherProps.className = '';
        }
        otherProps.className += ` ${classNames.join(' ')}`;
    }

    // Return the element whether as an HTML or span, depending on whether it has an actual link, or we just want it to
    // resemble a link.
    if (to || otherProps.href) {
        // Render <a> element for download links
        if (download) {
            const downloadOnClick = () => {
                // Call other onClick if present
                if (otherProps.onClick) {
                    otherProps.onClick();
                }

                // Send custom event to Google Analytics if we have gaLabel
                if (gaLabel) {
                    /* eslint-disable-next-line i18next/no-literal-string */
                    sendGoogleAnalyticsEvent('contents', 'download', gaLabel);
                }
            };

            return <a href={to} onClick={downloadOnClick} {...otherProps}>{children}</a>;
        }
        // External links open in new tab and use Google Analytics outbound link element
        return <OutboundLink href={to} {...otherProps} target="_blank">{children}</OutboundLink>;
    }

    return <span {...otherProps}>{children}</span>;
};

GeneralLink.propTypes = {
    activeClassName: PropTypes.string,
    partiallyActive: PropTypes.bool,
    to: PropTypes.string,
    forceActive: PropTypes.bool,
    gaLabel: PropTypes.string,
    other: PropTypes.any,
    children: PropTypes.node,
};

GeneralLink.defaultProps = {
    activeClassName: undefined,
    partiallyActive: undefined,
    to: undefined,
    forceActive: undefined,
    gaLabel: undefined,
    other: undefined,
    children: null,
};


/*
 * Public Elements
 */

// Base typography styles
const heroBaseStyle = css`
  ${commonTextStyles};

  font-size: 52px;
  line-height: 60px;
  font-weight: ${fontWeights.bold};
`;

const h1BaseStyle = css`
    ${commonTextStyles};

    font-size: 40px;
    line-height: 54px;
    font-weight: ${fontWeights.semiBold};
`;

const h2BaseStyle = css`
    ${commonTextStyles};

    font-size: 32px;
    line-height: 44px;
    font-weight: ${fontWeights.bold};
`;

const h3BaseStyle = css`
    ${commonTextStyles};

    font-size: 26px;
    line-height: 38px;
`;

const h4BaseStyle = css`
    ${commonTextStyles};

    font-size: 24px;
    line-height: 32px;
    font-weight: ${fontWeights.semiBold};
`;

const h4MonoBaseStyle = css`
    ${commonTextStyles};

    font-size: 24px;
    line-height: 30px;
    font-family: ${fonts.monospace};
`;

const h5BaseStyle = css`
    ${commonTextStyles};

    font-size: 20px;
    line-height: 30px;
    font-weight: ${fontWeights.semiBold};
`;

const h6BaseStyle = css`
    ${commonTextStyles};

    font-size: 18px;
    line-height: 26px;
    font-weight: ${fontWeights.semiBold};
`;

const h6MonoBaseStyle = css`
    ${commonTextStyles};

    font-size: 18px;
    line-height: 24px;
    font-family: ${fonts.monospace};
`;

const p2BaseStyle = css`
    ${commonTextStyles};

    font-size: 14px;
    line-height: 22px;
`;

// Typography
const Hero = styled.h1`
    ${heroBaseStyle};

    @media not all and (min-width: ${breakpoints.lg}) {
        ${h2BaseStyle};
    }
`;

const H1 = styled.h1`
    ${h1BaseStyle};

    @media not all and (min-width: ${breakpoints.lg}) {
        ${h4BaseStyle};
        font-weight: ${fontWeights.bold};
    }
`;

const H2 = styled.h2`
    ${h2BaseStyle};

    @media not all and (min-width: ${breakpoints.md}) {
        ${h4BaseStyle};
    }
`;

const H2Mono = styled.h2`
    ${commonTextStyles};

    font-size: 32px;
    line-height: 42px;
    font-family: ${fonts.monospace};
`;

const H3 = styled.h3`
    ${h3BaseStyle};
`;

const H3Mono = styled.h3`
    ${commonTextStyles};

    font-size: 26px;
    line-height: 36px;
    font-family: ${fonts.monospace};
`;

const H4 = styled.h4`
    ${h4BaseStyle};

    @media not all and (min-width: ${breakpoints.md}) {
        ${h6BaseStyle};
    }
`;

const H4Mono = styled.h4`
    ${h4MonoBaseStyle};

    @media not all and (min-width: ${breakpoints.md}) {
        ${h6MonoBaseStyle};
    }
`;

const H5 = styled.h5`
    ${h5BaseStyle};

    @media not all and (min-width: ${breakpoints.lg}) {
        ${h6BaseStyle};
    }
`;

const H5Mono = styled.h5`
    ${commonTextStyles};

    font-size: 20px;
    line-height: 28px;
    font-family: ${fonts.monospace};
`;

const H6 = styled.h6`
    ${h6BaseStyle};
`;

const H6Mono = styled.h6`
    ${h6MonoBaseStyle};
`;

const P = styled.p`
    ${commonTextStyles};

    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0.65rem;
`;

const PMono = styled(P)`
    ${commonTextStyles};

    font-size: 16px;
    line-height: 22px;
    font-family: ${fonts.monospace};
`;

const P2 = styled(P)`
    ${p2BaseStyle};
`;

const P2Mono = styled(P)`
    ${commonTextStyles};

    font-size: 14px;
    line-height: 20px;
    font-family: ${fonts.monospace};
`;

const StyledLink = styled(GeneralLink)`
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-decoration: none;
    background-color: ${colors.transparent};
`;

const Link = styled(StyledLink)`
    color: ${colors.black};
`;

const LinkColored = styled(StyledLink)`
    color: ${props => props.color};
    
    &:visited {
        color: ${props => props.color};
    }
    
    // ":hover" must come AFTER ":visited" so that it supersedes the color in the ":visited" selector
    &:hover {
        color: ${props => props.hovercolor};
        text-decoration: none;
    }
`;

const LinkDark = styled(StyledLink)`
    color: ${colors.white};

    &:visited {
      color: ${colors.white};
    }

    // ":hover" must come AFTER ":visited" so that it supersedes the color in the ":visited" selector
    &:hover {
      color: ${colors.purple};
      text-decoration: none;
    }
`;

const CTALink = styled(StyledLink)`
    ${commonTextStyles};

    font-size: 12px;
    line-height: 16px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: ${colors.darkPurple};
    font-weight: ${fontWeights.semiBold};
    cursor: pointer;

    &:visited {
      color: ${colors.darkPurple};
    }

    // ":hover" must come AFTER ":visited" so that it supersedes the color in the ":visited" selector
    &:hover {
      color: ${colors.darkPurple};
      text-decoration: none;
    }
`;

const CTALinkDark = styled(CTALink)`
    color: ${colors.white};

    &:visited {
      color: ${colors.white};
    }

    // ":hover" must come AFTER ":visited" so that it supersedes the color in the ":visited" selector
    &:hover {
      color: ${colors.white};
    }
`;

const UL = styled.ul`
    ${commonTextStyles};

    li {
        margin-bottom: 0.8em;
    }

    li::marker {
        font-size: 0.6em;
    }
`;

const StyledHighlighted = styled.span`
    background: linear-gradient(to bottom, ${colors.transparent} 50%, #D9DAFF 50%);
    display: inline;

    &.dark {
        background: linear-gradient(to bottom, ${colors.transparent} 50%, ${colors.darkPurple} 50%);
    }
`;

const Highlighted = ({dark = false, children}) => (
    <StyledHighlighted className={dark ? 'dark' : ''}>{children}</StyledHighlighted>
);

Highlighted.propTypes = {
    dark: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Highlighted.defaultProps = {
    dark: false,
};

const Quote = styled(Hero)`
    &:before {
        content: open-quote;
        display: inline-block;
        vertical-align: top;
        color: ${colors.darkPurple};
        font-family: ${fonts.monospace};
        position: relative;
        top: -10px;
    }
`;

const StyledBlockQuote = styled.div`
    display: flex;
    flex-direction: row;

    blockquote {
        margin: 0;
    }
`;

const BlockQuote = ({children}) => (
    <StyledBlockQuote className="blockquote">
        <Quote />
        <blockquote>{children}</blockquote>
    </StyledBlockQuote>
);

BlockQuote.propTypes = {
    children: PropTypes.node.isRequired,
};

const StyledFigcaption = styled.figcaption`
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.mediumGrey};
  font-family: ${fonts.monospaceRegular};
  text-align: ${props => props.align};
`;

const Figcaption = ({align, children}) => (
    <StyledFigcaption align={align}>{children}</StyledFigcaption>
);

Figcaption.propTypes = {
    children: PropTypes.node.isRequired,
    align: PropTypes.string,
};

Figcaption.defaultProps = {
    // eslint-disable-next-line i18next/no-literal-string
    align: 'left',
};


/*
 * Exports
 */
export {
    heroBaseStyle,
    h1BaseStyle,
    h2BaseStyle,
    h3BaseStyle,
    h4BaseStyle,
    h4MonoBaseStyle,
    h5BaseStyle,
    h6BaseStyle,
    h6MonoBaseStyle,
    p2BaseStyle,
    Hero,
    H1,
    H2,
    H2Mono,
    H3,
    H3Mono,
    H4,
    H4Mono,
    H5,
    H5Mono,
    H6,
    H6Mono,
    CTALink,
    CTALinkDark,
    Link,
    LinkColored,
    LinkDark,
    P,
    PMono,
    P2,
    P2Mono,
    UL,
    Highlighted,
    Quote,
    BlockQuote,
    Figcaption,
};
